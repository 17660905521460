<template>
  <div class="body">
    <div class="login">
      <p>欢迎使用云体系平台</p>
      <div class="input_Box" :class="{ activeClass: inpIndex == 3 }">
        <img
          src="../../assets/images/login1.png"
          style="position: absolute; left: 70px; top: 19px; z-index: 16"
        />
        <el-input
          type="text"
          @focus="clickInput(3)"
          placeholder="请输入账号"
          v-model="userNo"
        >
        </el-input>
      </div>
      <div class="input_Box" :class="{ activeClass: inpIndex == 3 }">
        <img
          src="../../assets/images/login2.png"
          style="position: absolute; left: 70px; top: 19px; z-index: 16"
        />
        <el-input
          type="password"
          @focus="clickInput(3)"
          placeholder="请输入密码"
          v-model="passWord"
        >
        </el-input>
      </div>
      <div class="input_Box" :class="{ activeClass: inpIndex == 3 }">
        <img
          src="../../assets/images/login2.png"
          style="position: absolute; left: 70px; top: 19px; z-index: 16"
        />
        <el-input
          type="text"
          placeholder="请输入验证码"
          @focus="clickInput(3)"
          v-model="code"
          maxlength="4"
        >
        </el-input>
        <img :src="imgUrl" alt="" class="code-img" @click="getCode" />
      </div>
      <div class="bottom" @click="login">登录</div>
    </div>
    <div class="bottom-box">
      <span>广州市景翔信息科技有限公司</span>
    </div>
  </div>
</template>

<script>
import { get, getUserCode } from "../../api/http";
export default {
  data() {
    return {
      inpIndex: 0,
      imgUrl: "",
      guid: "",
      code: "",
      passWord: "",
      userNo: "",
      dialogVisible: false,
      formLabelAlign: {},
    };
  },
  mounted() {
    this.getCode();
    if (this.$route.query.username) {
      this.userNo = this.$route.query.username;
      this.passWord = this.$route.query.userno;
    }
  },
  methods: {
    clickInput(i) {
      this.inpIndex = i;
    },
    getCode() {
      getUserCode("/api/UserCode")
        .then((resp) => {
          if (resp.status == 200) {
            this.imgUrl = window.URL.createObjectURL(resp.data);
            this.guid = resp.headers["content-type"].split("; ")[1];
          } else {
            this.$message.error("获取失败");
          }
        })
        .catch(() => {
          this.$message.error("获取失败");
        });
    },
    login() {
      get(
        "/api/DoLogin/DoLogin?UserNo=" +
          this.userNo +
          "&PassWord=" +
          this.passWord +
          "&UserCode=" +
          this.code +
          "&GuId=" +
          this.guid
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "登录成功",
            });
            sessionStorage.setItem("userInfo", JSON.stringify(resp.data));
            sessionStorage.setItem(
              "token",
              JSON.stringify(resp.data.userToken)
            );
            this.$router.push("./home");
            this.getCompanyList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("登录失败");
        });
    },
    getCompanyList() {
      get("/api/Company/GetAllByUser").then((resp) => {
        this.$store.dispatch("companyUserList", resp.data);
        sessionStorage.setItem("companyUserList", JSON.stringify(resp.data));
      });
      get("/api/Company/GetAll").then((resp) => {
        if (resp.code == 200) {
          this.$store.dispatch("companyList", resp.data);
          sessionStorage.setItem("companyList", JSON.stringify(resp.data));
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: url("../../assets/images/login.png");
  background-size: 100% 100%;
}

.login {
  z-index: 10;
  margin-top: 120px;
  display: inline-block;
  text-align: start;
  width: 400px;
  background: #ffffff;
  border: 2px solid #d5d5d5;
  box-shadow: 0px 9px 11px 2px rgba(109, 124, 134, 0.1);
  > p {
    font-size: 22px;
    font-weight: bold;
    color: #3fb3fc;
    text-align: center;
    padding-bottom: 20px;
    letter-spacing: 5px;
    margin-bottom: 45px;
    margin-top: 30px;
    text-shadow: 0px 9px 42px rgba(121, 168, 226, 0.09);
  }
  img {
    width: 16px;
    height: 16px;
    z-index: 10;
    margin-right: 40px;
  }
  input {
    width: 250px;
    outline: none;
    padding-left: 5px;
    color: #000000;
    z-index: 10;
    border-bottom: 1px solid #d5d5d5;
  }
  .code {
    position: relative;
    z-index: 10;
    input {
      z-index: 10;
      width: 170px;
    }
    .code-img {
      position: absolute;
      width: 80px;
      margin-left: 10px;
      height: 30px;
      right: 0;
      top: -10px;
      z-index: 10;
    }
  }
  & /deep/.el-input__inner {
    padding: 0 30px !important;
  }
}
.login div div {
  margin-top: 38px;
  z-index: 10;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.el-input {
  border: 0px !important;
  margin: 0px !important;
  height: 50px !important ;
  width: 60% !important;
  transform: scale(1.2);
}

.input_Box {
  position: relative;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  .code-img {
    position: absolute;
    width: 80px;
    margin-left: 0px;
    height: 30px;
    right: 20px;
    top: 10px;
    z-index: 10;
  }
}
.bottom {
  width: 70%;
  font-size: 14px;
  margin-top: 20px;
  height: 40px;
  z-index: 10;
  margin-left: 15%;
  background: linear-gradient(0deg, #0b6ae8 0%, #178ffe 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.bottom-box {
  position: absolute;
  text-align: center;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  color: black;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    letter-spacing: 5px;
    margin-left: 170px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
}
.activeClass {
  border-color: #178efe !important;
}
</style>
